



import { Vue, Component } from 'vue-property-decorator'
import DeviceItem from '@/components/device/item.vue'

@Component({ components: { DeviceItem } })
export default class ViewDeviceItem extends Vue {
  get id () {
    return this.$route.params.id
  }
}
